window.__utils = function () {
  const $window = $(window);

  // Function for reacting on window resize
  const reactOnResize = (reactionWidth, cb) => {
    let isSmaller = $window.width() < reactionWidth;

    cb(isSmaller);
    $window.on("resize", function () {
      const width = $(this).width();

      if (isSmaller && width > reactionWidth) {
        isSmaller = false;
        cb(isSmaller);
      } else if (!isSmaller && width < reactionWidth) {
        isSmaller = true;
        cb(isSmaller);
      }
    });
  };

  window.reactOnResize = reactOnResize;
};
