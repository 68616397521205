window.__initForm = function() {
  const $window = $(window);
  const tippingPoint = 992;
  const tippingPointMd = 768;

  // Check mobile screen height
  const appHeight = () => {
    document.documentElement.style.setProperty("--app-height", `${window.innerHeight}px`);
  };

  // ** Init phone number mask
  $("input.input-phone").mask("+0 (000) 000-0000");

  // ** Form validation
  // Validators
  const required = value => !!value.toString();
  const email = value => /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test(value);
  const phone = value => /^\+\d\s\(\d{3}\)\s\d{3}-\d{4}$/.test(value);
  const number = value => !isNaN(value);
  const minLength = min => value => typeof value === "string" && value.length > min;
  const maxLength = max => value => typeof value === "string" && value.length < max;

  // Messages
  const requiredMessage = "This field is required";

  // Validation handling
  function validateForm({ $form, rules, handleSubmit }) {
    // Init variables
    let invalidFields = new Array();
    const validateItems = new Array();
    const inputs = $form
      .find(".input")
      .map(function() {
        const name = $(this).attr("name");
        const fieldRules = rules[name];

        if (name && fieldRules) {
          return {
            name,
            rules: fieldRules,
            field: $(this).get(0)
          };
        }
      })
      .toArray()
      .filter(Boolean);

    // Init validation
    $form.attr("novalidate", true);
    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      const $input = $(input.field);
      const $inputGroup = $input.parents(".form-group");
      const $errorMessage = $inputGroup.find(".error-message");

      if ($inputGroup.get(0) && $errorMessage.get(0)) {
        function validate() {
          const value = $(this).val();

          for (let j = 0; j < input.rules.length; j++) {
            const rule = input.rules[j];

            if (rule.validator(value)) {
              invalidFields = invalidFields.filter(name => name !== input.name);
              $inputGroup.removeClass("error");
            } else {
              $inputGroup.addClass("error");
              $errorMessage.text(rule.message);

              if (invalidFields.indexOf(input.name) === -1) {
                invalidFields.push(input.name);
              }

              break;
            }
          }
        }

        validateItems.push({ validate, input: input.field });
        $input.bind("input blur", validate);
      }
    }

    // Handle form submit
    $form.on("submit", function(event) {
      event.preventDefault();

      for (let i = 0; i < validateItems.length; i++) {
        const item = validateItems[i];

        item.validate.call(item.input);
      }

      if (!invalidFields.length) {
        const values = $(this)
          .find("input, textarea")
          .map(function() {
            return {
              name: $(this).attr("name"),
              value: $(this).val(),
              field: this
            };
          })
          .toArray();

        handleSubmit(values);
      }
    });
  }

  $("form.contact-form").each(function() {
    validateForm({
      $form: $(this),
      handleSubmit(fields) {
        console.log("fields :", fields);
      },
      rules: {
        name: [{ validator: required, message: requiredMessage }],
        email: [
          { validator: required, message: requiredMessage },
          { validator: email, message: "Invalid email" }
        ],
        phone: [
          { validator: required, message: requiredMessage },
          { validator: phone, message: "Phone is invalid" }
        ]
      }
    });
  });

  // Modal windows
  function toggleModal(modalId) {
    const $modalWrap = $(`.modal-wrap[data-id=${modalId}]`);

    if ($modalWrap.get(0)) {
      $(document.body).toggleClass("overflow-hidden");
      $modalWrap.toggleClass("modal-active");

      if ($modalWrap.hasClass("modal-active")) {
        $modalWrap.css("z-index", 999999);
      } else {
        setTimeout(() => {
          $modalWrap.css("z-index", -1);
        }, 300);
      }
    }
  }

  $(".modal-btn").on("click", function() {
    const id = $(this).data("id");
    toggleModal(id);
  });

  $(".modal-wrap").each(function() {
    const id = $(this).data("id");
    const $modalCloseBtn = $(this).find(".modal-close");

    $modalCloseBtn.click(() => {
      toggleModal(id);
    });
  });

  // Add padding top to search popup
  const $searchPopup = $(".modal-wrap .modal-search");
  const $fixedInput = $searchPopup.find(".search-input");
  const $searchPopupContent = $searchPopup.find(".modal-content");

  function checkWidth() {
    if ($window.width() > tippingPoint) {
      $searchPopupContent.css({
        paddingTop: $fixedInput.height() + 160
      });
    } else if ($window.width() <= tippingPoint && $window.width() > tippingPointMd) {
      $searchPopupContent.css({
        paddingTop: $fixedInput.height() + 130
      });
    } else {
      $searchPopupContent.css({
        paddingTop: $fixedInput.height() + 115
      });
    }
  }

  // Add button "clear" in search popup

  const $searchClearButton = $(".modal-search .clear");
  const $searchInput = $fixedInput.find(".input");

  $searchInput.on("input", function() {
    if ($searchInput.length && $searchInput.val().length) {
      $searchClearButton.addClass("clear-shown");
      $searchClearButton.on("click", function() {
        $searchInput.val("");
        $(this).removeClass("clear-shown");
      });
    } else {
      $searchClearButton.removeClass("clear-shown");
    }
  });

  checkWidth();
  $(window).on("resize", checkWidth);
};
