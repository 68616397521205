window.__initCollectionPage = function() {
  const tippingPoint = 992;

  // Collection products slider
  const $products = $(".collection-products");
  if ($products.get(0)) {
    function setSlidersDesktop() {
      const clearMethods = new Array();

      $products.each(function() {
        let slider = null;
        const $productsSlider = $(this).find(".swiper-container");
        const $slides = $(this).find(".swiper-slide");
        const $prevBtn = $(this).find(".prev");
        const $nextBtn = $(this).find(".next");

        slider = new Swiper($productsSlider.get(0), {
          allowTouchMove: true,
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 20,
          navigation: {
            nextEl: $nextBtn,
            prevEl: $prevBtn
          },
          breakpoints: {
            1200: {
              slidesPerView: 4,
              slidesPerGroup: 4,
              spaceBetween: 40
            }
          }
        });
      });
    }

    function setSlidersMobile() {
      const clearMethods = new Array();

      $products.each(function() {
        const $sliderWrap = $(this).find(".products-slider-mobile .swiper-wrapper");
        const $sliderContainer = $(this).find(".products-slider-mobile .swiper-container");
        const $productsPagination = $(this).find(".products-slider-mobile .swiper-pagination");

        if (!$sliderWrap.children().length) {
          $(this)
            .find(".products-slider-wrap .card")
            .each(function() {
              $(this)
                .clone()
                .removeAttr("data-slide-id")
                .appendTo($sliderWrap);
            });
        }

        const mobileSlider = new Swiper($sliderContainer, {
          pagination: {
            el: $productsPagination,
            type: "bullets",
            clickable: true
          },
          breakpoints: {
            320: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            577: {
              slidesPerView: 3,
              spaceBetween: 30
            }
          }
        });

        clearMethods.push(() => mobileSlider.destroy());
      });

      return function() {
        for (let clearMethod of clearMethods) {
          clearMethod();
        }
      };
    }

    let clearDesktopSliders = null;
    let clearMobileSliders = null;
    window.reactOnResize(tippingPoint, isSmaller => {
      if (clearDesktopSliders) clearDesktopSliders();
      if (clearMobileSliders) clearMobileSliders();

      if (isSmaller) {
        clearMobileSliders = setSlidersMobile();
      } else {
        clearDesktopSliders = setSlidersDesktop();
      }
    });
  }
};
