window.__initProductPage = function() {
  const $window = $(window);
  const animationDuration = 250;
  const $product = $(".product-description .product-slider-wrap");
  const $prevBtn = $product.find(".prev");
  const $nextBtn = $product.find(".next");
  const $pagination = $product.find(".swiper-pagination");

  // Product slider
  if ($product.get(0)) {
    $product.each(function() {
      let slider = null;

      const $productSlider = $(this).find(".swiper-container");
      const $slides = $(this).find(".swiper-slide");

      let slidesWrap = new Array();

      $slides.each(function(index, value) {
        let $slide = $(this);
        let $slidesObj = $slide.html();

        slidesWrap.push($slidesObj);
      });

      slider = new Swiper($productSlider, {
        loop: true,
        allowTouchMove: false,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },
        navigation: {
          nextEl: $nextBtn,
          prevEl: $prevBtn
        },
        pagination: {
          el: $pagination,
          clickable: true,
          renderBullet: function(index, className) {
            return (
              '<div class="' +
              className +
              " " +
              'slider-pagination">' +
              slidesWrap[index] +
              "</div>"
            );
          }
        }
      });
    });
  }

  // Positioning slider buttons

  const setHeight = () => {
    $prevBtn.css("bottom", 35 + $pagination.height());
    $nextBtn.css("bottom", 35 + $pagination.height());
  };

  // Add background to tables
  const $table = $(".product-description .content-feature .table");
  const $lightBlue = "#eff7fd";

  $table.each(function() {
    if (
      $(this)
        .find("th")
        .get(0)
    ) {
      $(this)
        .find("tr:nth-child(even)")
        .css("background-color", $lightBlue);
    } else {
      $(this)
        .find("tr:nth-child(odd)")
        .css("background-color", $lightBlue);
    }
  });

  // Accordion
  function accordion(sectionSelector, headingSelector, listSelector) {
    const composeToggleAccordion = $list =>
      function() {
        $(this).toggleClass("plus");

        $list.toggle(
          {
            height: "0",
            opacity: "0"
          },
          animationDuration
        );
      };

    $(sectionSelector).each(function() {
      const $list = $(this).find(listSelector);
      const $heading = $(this).find(headingSelector);

      if ($list.get(0) && $heading.get(0)) {
        const dataId = $(this).attr("data-id");
        const toggleAccordion = composeToggleAccordion($list);

        if (dataId === "1") {
          $heading.click(toggleAccordion);
        } else if (dataId === "0") {
          toggleAccordion.call($heading.get(0));
          $heading.click(toggleAccordion);
        }
      }
    });
  }

  // Set class to clicked filter feature

  const $featureContainer = $(".content-feature-static");

  $featureContainer.each(function() {
    const $feature = $(this).find(".sorted-card, .filter-card");

    $feature.each(function() {
      $(this).on("click", function() {
        if (
          $(this)
            .find(".input")
            .is(":checked")
        ) {
          $feature.removeClass("clicked");
          $(this).toggleClass("clicked");
        }
      });
    });
  });

  // Tabs

  $(".tab-nav-wrap a").click(function() {
    $(".tab-content").hide();
    $(".tab-nav-wrap a.active").removeClass("active");
    $(this).addClass("active");

    const $panel = $(this).attr("href");
    $($panel).fadeIn(1000);

    return false;
  });

  $(".tab-nav-wrap li:first a").click();

  // Dropdown

  function dropdown(section, buttons, list) {
    const $section = $(section);
    const $list = $section.find(list);

    $section.find(buttons).on("click", function() {
      $list.slideToggle(animationDuration);
    });
  }

  dropdown(".tabs", ".tab-nav-mobile", ".tab-nav-wrap");

  accordion(".content-feature-dynamic", ".content-feature-topic-wrap", ".content-feature-filling");

  setHeight();
  $window.on("resize", setHeight);
};
