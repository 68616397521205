window.__initHomePage = function() {
  const $window = $(window);
  const tippingPoint = 992;

  // Header sliders
  const $headerSection = $("section.header");
  if ($headerSection.get(0)) {
    // ** Initialization
    let sliders = new Array();
    const $headerMiniSliders = $(".header-content-slider-item");
    const $buttonsContainer = $headerSection.find(".header-content-numbers");
    const $mainSlider = $headerSection.find(".header-bg-wrap .swiper-container");
    const $miniSlider = $headerSection.find(".header-content-wrap .swiper-container");
    const miniSliderItemsNumber = $headerMiniSliders.length;
    const normalMode = miniSliderItemsNumber >= 3;
    const slidesPerView = normalMode ? 3 : 2;
    const mainSliderOffset = Math.round(slidesPerView / 2);
    const generalSettings = {
      loop: true,
      allowTouchMove: false,
      setWrapperSize: true,
      speed: 1000,
      autoplay: {
        delay: 3500,
      },
    };

    // ** Methods
    const normalizeSliderIndex = (slider) => {
      if (normalMode) {
        return 1 + (slider.__mainSlider || $window.width() < tippingPoint ? 0 : mainSliderOffset);
      } else {
        return 1 + (slider.__mainSlider ? 0 : 1);
      }
    };

    const slideOnClick = function(index) {
      $(this).click(function() {
        sliders.forEach((slider) => {
          slider.slideTo(index + normalizeSliderIndex(slider));
        });
      });
    };

    const toggleBtnOnSliderChange = ($buttons) =>
      function() {
        $buttons.removeClass("active-number");
        $buttons
          .get((this.activeIndex - normalizeSliderIndex(this)) % miniSliderItemsNumber)
          .classList.add("active-number");
      };

    // ** Setup
    // Append images to main slider
    const $mainSliderWrappers = $mainSlider.find(".swiper-wrapper");
    $miniSlider.find(".header-content-slider-item").each(function(index) {
      $(this)
        .find("img")
        .each(function(index) {
          const $newImage = $(this).clone();
          $newImage.get(0).className = "header-bg swiper-slide";
          $newImage.appendTo($mainSliderWrappers.get(index));
        });
    });

    if (miniSliderItemsNumber > 1) {
      // Normalize width for mini slider wrap
      if (!normalMode) {
        $miniSlider.css("max-width", 500);
      }

      // Init main slider
      $headerSection.find(".header-bg-wrap .swiper-container").each(function() {
        const slider = new Swiper(this, generalSettings);

        slider.__mainSlider = true;
        sliders.push(slider);
      });

      // Init mini slider
      $headerSection.find(".header-content-wrap .swiper-container").each(function() {
        const slider = new Swiper(this, {
          slidesPerView: 1,
          allowTouchMove: true,
          loop: true,
          speed: 1000,
          autoplay: {
            delay: 3500,
          },
          spaceBetween: 50,
          breakpoints: {
            993: {
              centeredSlides: normalMode,
              slidesPerView,
            },
          },
        });

        sliders.push(slider);
      });

      // Append nav buttons
      $buttonsContainer.append(
        [...new Array(miniSliderItemsNumber)].map((_, index) => {
          const displayWithZero = index + 1 < 10;
          return `<span class="header-content-numbers-item">${displayWithZero ? "0" : ""}${index +
            1}</span>`;
        })
      );

      // Listen click on button and toggle sliders
      const $buttons = $buttonsContainer.find(".header-content-numbers-item").each(slideOnClick);

      // Listen slider change and toggle nav buttons
      const miniSlider = sliders.find((slider) => !slider.__mainSlider);

      if (miniSlider) {
        miniSlider.on("slideChangeTransitionStart", toggleBtnOnSliderChange($buttons));
        toggleBtnOnSliderChange($buttons).call(miniSlider);
      }

      // Set default position for sliders
      for (let slider of sliders) {
        slider.slideTo(normalizeSliderIndex(slider));
      }

      // Toggle slider on click by slide
      $miniSlider.find(".header-content-slider-item").each(function() {
        slideOnClick.call(this, $(this).data("swiper-slide-index"));
      });

      // Add autoplay interval
      setInterval(() => {
        sliders.forEach((slider) => {
          slider.slideNext();
        });
      }, 3000);
    } else {
      $miniSlider.addClass("single-slide");
      $buttonsContainer.css("display", "none");
    }
  }

  // Collection sliders
  const $collections = $(".collections-item");
  if ($collections.get(0)) {
    function setSlidersDesktop() {
      const clearMethods = new Array();

      $collections.each(function() {
        // ** Initialization
        const sliders = new Array();
        const emptySliders = new Array();
        const $prevBtn = $(this).find(".prev");
        const $nextBtn = $(this).find(".next");
        const $miniSliderWrap = $(this).find(".collections-item-slider-column .swiper-wrapper");
        const mainSliderLength = $(this).find(".collections-item-slider-image-wrap .swiper-slide")
          .length;

        // ** Methods
        function toggleButtonActive() {
          if (this.activeIndex === mainSliderLength - 1) {
            $nextBtn.addClass("disabled");
          } else if ($nextBtn.hasClass("disabled")) {
            $nextBtn.removeClass("disabled");
          }

          if (this.activeIndex === 0) {
            $prevBtn.addClass("disabled");
          } else if ($prevBtn.hasClass("disabled")) {
            $prevBtn.removeClass("disabled");
          }
        }

        function slidePrev() {
          sliders.forEach((slider) => {
            slider.slidePrev();
          });
        }

        function slideNext() {
          sliders.forEach((slider) => {
            slider.slideNext();
          });
        }

        function onSliderChange() {
          toggleButtonActive.call(this);

          for (let emptySlider of emptySliders) {
            const [slider, indexes] = emptySlider;

            if (indexes.includes(this.activeIndex)) {
              slider.classList.add("empty");
            } else {
              slider.classList.remove("empty");
            }
          }
        }

        // ** Setup
        // Align mini sliders length
        $miniSliderWrap.each(function() {
          const emptySlides = new Array();
          let ids = $(this)
            .find(".swiper-slide")
            .map(function() {
              return $(this).data("slide-id");
            })
            .toArray();

          for (let i = 0; i < mainSliderLength; i++) {
            const $slides = $(this).find(".swiper-slide");

            if (ids[i] !== i + 1) {
              emptySlides.push(i);
              ids.splice(i, 0, i + 1);

              if ($slides.get(i)) {
                $($slides.get(i)).before(`<span class="swiper-slide"></span>`);
              } else {
                $($slides.get(i - 1)).after(`<span class="swiper-slide"></span>`);
              }
            }
          }

          emptySliders.push([this.parentNode, emptySlides]);
        });

        // Sliders initialization
        $(this)
          .find(".collections-item-slider .swiper-container")
          .each(function() {
            const slider = new Swiper(this, {
              setWrapperSize: true,
              allowTouchMove: false,
            });

            sliders.push(slider);
            clearMethods.push(() => slider.destroy());
          });

        // Connect sliders toggling
        $prevBtn.on("click", slidePrev);
        clearMethods.push(() => $prevBtn.off("click", slidePrev));
        $nextBtn.on("click", slideNext);
        clearMethods.push(() => $nextBtn.off("click", slideNext));

        toggleButtonActive.call(sliders[0]);
        onSliderChange.call(sliders[0]);
        sliders[0].on("slideChange", onSliderChange);
      });

      return function() {
        for (let clearMethod of clearMethods) {
          clearMethod();
        }
      };
    }

    function setSlidersMobile() {
      const clearMethods = new Array();

      $collections.each(function() {
        const $sliderWrap = $(this).find(".collections-item-mobile-slider .swiper-wrapper");
        const $sliderContainer = $(this).find(".collections-item-mobile-slider .swiper-container");
        const $collectionsPagination = $(this).find(
          ".collections-item-mobile-slider .swiper-pagination"
        );

        if (!$sliderWrap.children().length) {
          $(this)
            .find(".collections-item-slider .collections-item-slider-column-card")
            .each(function() {
              $(this)
                .clone()
                .removeAttr("data-slide-id")
                .appendTo($sliderWrap);
            });
        }

        const mobileSlider = new Swiper($sliderContainer, {
          pagination: {
            el: $collectionsPagination,
            type: "bullets",
            clickable: true,
          },
          breakpoints: {
            320: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            577: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          },
        });

        clearMethods.push(() => mobileSlider.destroy());
      });

      return function() {
        for (let clearMethod of clearMethods) {
          clearMethod();
        }
      };
    }

    let clearDesktopSliders = null;
    let clearMobileSliders = null;
    window.reactOnResize(tippingPoint, (isSmaller) => {
      if (clearDesktopSliders) clearDesktopSliders();
      if (clearMobileSliders) clearMobileSliders();

      if (isSmaller) {
        clearMobileSliders = setSlidersMobile();
      } else {
        clearDesktopSliders = setSlidersDesktop();
      }
    });
  }

  // Bestseller sliders
  const $bestsellers = $(".bestsellers");
  if ($bestsellers.get(0)) {
    $bestsellers.each(function() {
      let slider = null;
      const $bestsellersSlider = $(this).find(".swiper-container");
      const $slides = $(this).find(".swiper-slide");
      const $prevBtn = $(this).find(".prev");
      const $nextBtn = $(this).find(".next");

      window.reactOnResize(tippingPoint, (isSmaller) => {
        if (isSmaller) {
          if (slider) slider.destroy();

          if ($slides.length >= 8) {
            $slides.each(function(index) {
              if (index >= 8) {
                const $img = $(this).find("img");
                const src = $img.attr("src");

                $(this).css("display", "none");
                $img.attr("src", "").attr("data-src", src);
              }
            });
          }
        } else {
          if ($slides.length > 8) {
            $slides.each(function() {
              const $img = $(this).find("img");
              if ($img.data("src")) {
                $(this).removeAttr("style");
                $img.attr("src", $img.data("src"));
              }
            });
          }

          slider = new Swiper($bestsellersSlider.get(0), {
            allowTouchMove: false,
            slidesPerView: 4,
            slidesPerColumn: 2,
            slidesPerGroup: 4,
            spaceBetween: 20,
            slidesPerColumnFill: "row",
            direction: "horizontal",
            navigation: {
              nextEl: $nextBtn,
              prevEl: $prevBtn,
            },
            breakpoints: {
              1200: {
                slidesPerView: 4,
                slidesPerColumn: 2,
                slidesPerGroup: 4,
                spaceBetween: 50,
              },
            },
          });
        }
      });
    });
  }

   // Sorting sliders
   const $sortingItems = $(".sorting-slider-wrap");

   if ($sortingItems.get(0)) {
     $sortingItems.each(function() {
       const $that = $(this);
       const $sortingSlider = $that.find(".swiper-container");
       const $slides = $that.find(".swiper-slide");
       const $prevBtn = $that.find(".prev");
       const $nextBtn = $that.find(".next");
       let n;
       let nBigger;
       let slider = null;
       let sliderBigger = null;
 
       reactOnResize(992, (isSmaller) => {
         if (!isSmaller) {
           n = 7;
           nBigger = 6;
           initSliders();
         } else {
           reactOnResize(768, (isSmaller) => {
             if (!isSmaller) {
               n = 6;
               nBigger = 5;
               initSliders();
             } else {
               reactOnResize(576, (isSmaller) => {
                 if (!isSmaller) {
                   n = 4;
                   nBigger = 4;
                   initSliders();
                 } else {
                   n = 3;
                   nBigger = 3;
                   initSliders();
                 }
               });
             }
           });
         }
       });
 
       function initSortingSlider() {
         slider = new Swiper($sortingSlider, {
           slidesPerView: 7,
           spaceBetween: 25,
           loop: true,
           navigation: {
             nextEl: $nextBtn,
             prevEl: $prevBtn,
           },
           pagination: {
             el: ".swiper-pagination",
             type: "bullets",
             clickable: true,
           },
           breakpoints: {
             992: {
               slidesPerView: 7,
               spaceBetween: 25,
             },
             768: {
               slidesPerView: 5,
               spaceBetween: 25,
             },
             576: {
               slidesPerView: 4,
               spaceBetween: 25,
             },
             320: {
               slidesPerView: 3,
               spaceBetween: 15,
             },
           },
         });
       }
 
       function initSortingBigSlider() {
         sliderBigger = new Swiper($sortingSlider, {
           slidesPerView: 6,
           spaceBetween: 20,
           loop: true,
           navigation: {
             nextEl: $nextBtn,
             prevEl: $prevBtn,
           },
           pagination: {
             el: ".swiper-pagination",
             type: "bullets",
             clickable: true,
           },
           breakpoints: {
             992: {
               slidesPerView: 6,
               spaceBetween: 20,
             },
             768: {
               slidesPerView: 5,
               spaceBetween: 17,
             },
             576: {
               slidesPerView: 4,
               spaceBetween: 17,
             },
             320: {
               slidesPerView: 3,
               spaceBetween: 15,
             },
           },
         });
       }
 
       function initSliders() {
         if ($that.hasClass("sorting-bigger")) {
           initSortingBigSlider();
 
           if ($slides.length <= nBigger) {
             sliderBigger.destroy();
             $that.addClass("not-swiped");
           } else {
             initSortingBigSlider();
             $that.removeClass("not-swiped");
           }
         } else {
           initSortingSlider();
 
           if ($slides.length <= n) {
             if(slider) slider.destroy();
             //slider.destroy();
             //slider = null;
             $that.addClass("not-swiped");
            } else if ($slides.length > n && slider !== null) {
              initSortingSlider();
              $that.removeClass("not-swiped");
            }
          }
        }
        
        console.log(slider);
       initSliders();
     });
   }

  // Add topbar padding
  const $topBar = $("header.topbar");
  const setPadding = () => {
    $("body").css("padding-top", $topBar.innerHeight());
  };

  // Add topbar shadow
  function scrollFunction() {
    if (
      document.body.scrollTop > $topBar.height() ||
      document.documentElement.scrollTop > $topBar.height()
    ) {
      $topBar.css("box-shadow", "0 4px 35px rgba(180, 180, 180, 0.25)");
    } else {
      $topBar.css("box-shadow", "");
    }
  }

  // Add scroll to submenu
  function checkSubmenuViewport(el) {
    const elementTop = el.offset().top;
    const elementBottom = elementTop + el.outerHeight();
    const viewportTop = $(window).scrollTop();
    const viewportBottom = viewportTop + $(window).height();
    //console.log(elementBottom > viewportBottom);
    //console.log("element " + elementBottom);
    //console.log("screen " + viewportBottom);

    return elementBottom > viewportBottom;
  }

  function setScrollToSubmenu() {
    if (checkSubmenuViewport($(".submenu .wrap"))) {
      $("body").addClass("overflow-hidden");
      $(".submenu .wrap").addClass("big");
    } else {
      $("body").removeClass("overflow-hidden");
      $(".submenu .wrap").removeClass("big");
    }
  }

  $(".submenu").on("mouseenter", (event) => {
    event.stopPropagation();
    setScrollToSubmenu();
  });

  $(".submenu .wrap").on("mouseenter", (event) => {
    event.stopPropagation();
    setScrollToSubmenu();
  });

  $(".submenu").on("mouseleave", function() {
    $("body").removeClass("overflow-hidden");
    $(".submenu .wrap").removeClass("big");
  });

  $(".submenu .wrap").on("mouseleave", function() {
    $("body").removeClass("overflow-hidden");
    $(".submenu .wrap").removeClass("big");
  });

  // Topbar menu
  function checkWidth() {
    if ($window.width() > tippingPoint) {
      $(".topbar-nav").attr("style", "");
    }
  }

  checkWidth();
  $(window).resize(checkWidth);

  const $topbarNavWrap = $(".topbar-nav-wrap");

  $(".topbar-nav-toggle.close").click(function() {
    $(".topbar").addClass("topbar-active");
    $topbarNavWrap.removeClass("toggle-menu");

    void $topbarNavWrap.get(0).offsetWidth;

    $topbarNavWrap.css({ animationDirection: "" });
    $("body").addClass("overflow-hidden");

    $topbarNavWrap.addClass("toggle-menu");
  });

  if ($(window).width() <= 992) {
    changeMobileMenu();
  }

  reactOnResize(992, (isSmaller) => {
    if (isSmaller) {
      changeMobileMenu();
    }
  });

  function changeMobileMenu() {
    $(".topbar-nav-sub-col").each(function() {
      $(this)
        .find(".title")
        .click(function() {
          $(this)
            .parent()
            .addClass("chosen");

          $(".topbar-nav-item .wrap").addClass("hidden");
          $("body").addClass("overflow-hidden");

          if (
            $(this)
              .parent()
              .hasClass("chosen")
          ) {
            $(this).parent().append(`
        <button class="menu-arrow">
          <img class="arrow" src="img/menu-arrow.svg">
        </button>
        `);
            $(".menu-arrow").on("click", function() {
              //console.log(1);
              $(".topbar-nav-sub-col").removeClass("chosen");
              $(".topbar-nav-item .wrap").removeClass("hidden");
              $(".menu-arrow").remove();
            });
          } else {
            $(".menu-arrow").remove();
            $(this)
              .parent()
              .removeClass("chosen");
            $(".topbar-nav-item .wrap").removeClass("hidden");
          }
        });
    });
  }

  $(".topbar-nav-toggle.open").click(function() {
    $(".topbar").removeClass("topbar-active");
    $topbarNavWrap.removeClass("toggle-menu");

    void $topbarNavWrap.get(0).offsetWidth;

    $topbarNavWrap.css({ animationDirection: "reverse" });
    $("body").removeClass("overflow-hidden");

    $topbarNavWrap.addClass("toggle-menu");

    $(".topbar-nav-sub-col").removeClass("chosen");
    $(".topbar-nav-item .wrap").removeClass("hidden");
    $(".menu-arrow").remove();
  });

  setPadding();
  $window.on("resize", setPadding);
  $window.on("scroll", scrollFunction);
};
