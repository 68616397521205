window.__initFilterPage = function() {
  const animationDuration = 250;
  const $window = $(window);
  const tippingPoint = 768;
  let pagination = parseInt($(".number-wrap").attr("actnumber"), 10);
  const $btnPrev = $("nav.number-pagination").find(".prev-pagination");
  const $btnNext = $("nav.number-pagination").find(".next-pagination");

  // Pagination

  $btnPrev.on("click", function() {
    let actNumber = $(this)
      .attr("href")
      .replace("#!", "");
    if (actNumber === "-1") {
      pagination--;
      if ($(".number-wrap").attr("actnumber") == "1") {
        $btnPrev.attr("disabled", "");
      }
    } else {
      pagination = parseInt(actNumber, 10);
    }
    $(".number-wrap").attr("actnumber", pagination);
  });

  $(".number-link").on("click", function() {
    let actNumber = $(this)
      .attr("href")
      .replace("#!", "");
    if (actNumber === "+1") {
      pagination++;
    } else if (actNumber === "-1") {
      pagination--;
    } else {
      pagination = parseInt(actNumber, 10);
    }
    $(".number-wrap").attr("actnumber", pagination);

    $(".number").each(function(i) {
      if (i + 1 == actNumber) {
        $(this).addClass("current");
      } else {
        $(this).removeClass("current");
      }
    });
  });

  // Price range input

  const $rangeBar = $(".range-bar");

  const min = +$rangeBar.attr("data-min");
  const max = +$rangeBar.attr("data-max");
  let chosenMin = +$rangeBar.attr("data-from");
  let chosenMax = +$rangeBar.attr("data-to");

  $rangeBar.slider({
    range: true,
    min: min,
    max: max,
    values: [chosenMin, chosenMax],
    slide: function(event, ui) {
      $(".amount-left").text(" $" + ui.values[0]);
      $(".amount-right").text(" $" + ui.values[1]);

      $("#price_min").val(ui.values[0]);
      $("#price_max").val(ui.values[1]);
      $("#price_changed").val(1);
      $("#price_changed").change();

      chosenMin = ui.values[0];
      chosenMax = ui.values[1];
      $rangeBar.attr("data-from", chosenMin);
      $rangeBar.attr("data-to", chosenMax);
    },
  });

  $(".amount-left").text(" $" + $rangeBar.slider("values", 0));
  $(".amount-right").text(" $" + $rangeBar.slider("values", 1));

  // Accordion
  function accordion(sectionSelector, headingSelector, listSelector) {
    const composeToggleAccordion = ($list) =>
      function() {
        $(this).toggleClass("plus");
        $list.toggle(
          {
            height: "0",
            opacity: "0",
          },
          animationDuration
        );
      };

    $(sectionSelector).each(function() {
      const $list = $(this).find(listSelector);
      const $heading = $(this).find(headingSelector);

      if ($heading.hasClass("plus")) {
        $list.css({
          display: "none",
        });
      }

      if ($list.get(0) && $heading.get(0)) {
        const toggleAccordion = composeToggleAccordion($list);

        //toggleAccordion.call($heading.get(0));
        $heading.click(toggleAccordion);
      }
    });
  }

  // Checked all in feature checkbox
  $(".filter-side-features")
    .find(".feature .filter-checkbox")
    .each(function() {
      const $allCheckbox = $(this).find("input[type=checkbox].all");

      if ($allCheckbox.get(0)) {
        // ** Initialization
        const state = {
          isAllSelected: false,
          isAllCheckboxSelected: false,
          selectedItems: new Set(),
        };

        const $inputs = $(this)
          .find("input[type=checkbox]")
          .not($allCheckbox)
          .not(":disabled");

        // ** Methods
        const update = () => {
          $allCheckbox.prop("checked", state.isAllCheckboxSelected);
        };

        const $allCheckboxes = $(this)
          .find("input[type=checkbox]")
          .not(":disabled");

        // $allCheckboxes.each(function(index) {
        //   if (this.checked) {
        //     state.selectedItems.add(index);
        //     console.log(state.selectedItems);
        //   }
        // });

        // ** Events handling
        $allCheckbox.change(function() {
          $inputs.prop("checked", false);

          if (this.checked) {
            for (let i = 0; i < $inputs.length; i++) {
              state.selectedItems.add(i);
            }
            state.isAllSelected = false;
            state.isAllCheckboxSelected = true;
            $inputs.prop("checked", state.isAllSelected);
          } else {
            state.selectedItems.clear();
            state.isAllSelected = false;
            state.isAllCheckboxSelected = false;
          }

          console.log(state.selectedItems);
        });

        $inputs.each(function(index) {
          if (this.checked) {
            state.selectedItems.add(index);
            console.log(state.selectedItems);

            if (state.selectedItems.size === $inputs.length) {
              state.isAllCheckboxSelected = true;
              $inputs.prop("checked", false);
              update();
            } else {
              state.isAllCheckboxSelected = false;
              update();
            }
          }

          $(this).change(function() {
            if ($allCheckbox.prop("checked")) {
              state.selectedItems.clear();
            }

            if (this.checked) {
              state.selectedItems.add(index);
              $(this).prop("checked", this.checked);
            } else if (state.selectedItems.has(index)) {
              state.selectedItems.delete(index);
              $(this).prop("checked", false);
            }

            if (state.selectedItems.size === $inputs.length) {
              state.isAllCheckboxSelected = true;
              $inputs.prop("checked", false);
              update();
            } else {
              state.isAllCheckboxSelected = false;
              update();
            }

            console.log(state.selectedItems);
          });
        });
      }
    });

  // Dropdown

  function dropdown(section, buttons, list) {
    const $section = $(section);
    const $list = $section.find(list);

    $section.find(buttons).on("click", function() {
      $list.slideToggle(animationDuration);
    });
  }

  // Add class to dropdown item

  function addClass(item, className) {
    const $item = $(item);
    const $className = className;

    $item.each(function() {
      $(this).on("click", function() {
        $item.removeClass($className);
        $(this).addClass($className);
      });
    });
  }

  addClass(".feature-category .sub-item", "active-item");
  addClass(".filter-result .sorted-by .sub-item", "active-item");

  // Toggle filter menu
  $(".mobile-feature-button").click(function() {
    $(".filter-side").addClass("filter-active");
    $("body").addClass("overflow-hidden");
    checkWidth();
    $window.on("resize", checkWidth);
  });

  $(".clear-x").click(function() {
    $(".filter-side").removeClass("filter-active");
    $("body").removeClass("overflow-hidden");
  });

  // Check screen width
  const $filterSideFeatures = $(".filter-side-features");
  const $topFilter = $("form.feature-filter");
  const $bottomFilter = $("div.filter-side-submit");

  function checkWidth() {
    if ($window.width() > tippingPoint) {
      $filterSideFeatures.removeAttr("style");
      $("body").removeClass("overflow-hidden");
    } else {
      $filterSideFeatures.css({
        paddingTop: $topFilter.height() + 54,
        paddingBottom: $bottomFilter.height() + 24,
      });

      if ($(".filter-side").hasClass("filter-active")) {
        $("body").addClass("overflow-hidden");
      } else {
        $("body").removeClass("overflow-hidden");
      }
    }
  }

  //checkWidth();
  //$window.on("resize", checkWidth);

  accordion(".feature", ".feature-topic", ".feature-content");
  dropdown(".feature-category", ".feature-content", ".sub-items-wrap");
  dropdown(".topper", ".sorted-by", ".sub-items-wrap");
};
